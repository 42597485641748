import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

function getMinistryAllocation (datas, districtId, fertilizerId) {
  const ministry = datas.ministry.filter(tsp => tsp.fertilizer_id === fertilizerId && tsp.district_id === parseInt(districtId))
  return ministry.length ? calculateTotal(ministry) : ''
}
function getBadcAllocation (datas, districtId, fertilizerId) {
  const badc = datas.badc.filter(tsp => tsp.fertilizer_id === fertilizerId && tsp.district_id === parseInt(districtId))
  return badc.length ? calculateTotal(badc) : ''
}
function getWarehouseName (datas, districtId, type) {
  const warehouses = datas.warehouse.filter(w => w.district_id === parseInt(districtId))
  let wareHoueInfo = ''
    if (warehouses.length > 0) {
      warehouses.map((item, index) => {
        if (index !== 0) {
          wareHoueInfo = wareHoueInfo + '/' + item[type]
        } else {
          wareHoueInfo = item[type]
        }
      })
    }
    return wareHoueInfo
}
function calculateTotal (data) {
    const total = data.map(r => r.quantity).reduce((a, c) => {
        a = a ? parseInt(a) : 0
        c = c ? parseInt(c) : 0
        return a + c
    })
    return total
}
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, dataArray, vm, monthName, allocationType, search, fertilizerNameList) => {
    try {
        Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        const pdfContent = [
          {
              columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          {
            columns: [
              { width: '*', text: '' },
              { width: '*', text: '' },
              { width: '*', text: vm.$t('fertilizerReport.krishi_bhaban') },
              { width: '*', text: '' },
              { width: '*', text: '' }
            ],
            style: 'krishi'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]

        if (reportHeadData.projectName) {
          pdfContent.push({ text: reportHeadData.projectName, style: 'header3', alignment: 'center' })
        }

        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center', decoration: 'underline' })
        const allRowsHead = [
          [
            { text: vm.$t('fertilizerSales.month'), alignment: 'right', style: 'td' },
            { text: ':', alignment: 'center', style: 'td' },
            { text: search.month_name, alignment: 'left', style: 'td' }
          ],
          [
            { text: vm.$t('fertilizerSales.allocationType'), alignment: 'right', style: 'td' },
            { text: ':', alignment: 'center', style: 'td' },
            { text: search.allo_type, alignment: 'left', style: 'td' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['45%', '5%', '45%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
      pdfContent.push({ text: vm.$t('fertilizerReport.accountMayTons'), style: 'fertilizer', alignment: 'right', bold: true })
      const allRows = [
        [
          { text: vm.$t('globalTrans.sl_no'), rowSpan: 3, style: 'th', alignment: 'center' },
          { text: vm.$t('fertilizerReport.district_name'), rowSpan: 3, style: 'th', alignment: 'center' },
          { text: vm.$t('fertilizerReport.ministry_allocation_of_fer'), colSpan: 3, style: 'th', alignment: 'center' },
          {},
          {},
          { text: vm.$t('movement.warehouseName'), rowSpan: 3, style: 'th', alignment: 'center' },
          { text: vm.$t('fertilizerReport.warehouse_wased_fer'), colSpan: 3, style: 'th', alignment: 'center' },
          {},
          {},
          { text: vm.$t('fertilizerReport.comments'), rowSpan: 3, style: 'th', alignment: 'center' }
        ],
        [
          {},
          {},
          { text: vm.$t('movement.fertilizer_name'), colSpan: 3, style: 'th', alignment: 'center' },
          {},
          {},
          {},
          { text: vm.$t('movement.fertilizer_name'), colSpan: 3, style: 'th', alignment: 'center' },
          {},
          {},
          {}
        ]
      ]
      const headList = [
        {},
        {}
      ]
      fertilizerNameList.map((item, index) => {
        headList.push(
          { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'right' }
        )
      })
      headList.push({})
      fertilizerNameList.map((item, index) => {
        headList.push(
          { text: vm.$i18n.locale === 'en' ? item.text_en : item.text_bn, style: 'th', alignment: 'right' }
        )
      })
      headList.push({})
      allRows.push(headList)
      Store.state.commonObj.districtList.map((dis, index) => {
          const newRow = [
            { text: vm.$n(index + 1), style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? dis.text_bn : dis.text_en, style: 'td', alignment: 'center' }
          ]
          fertilizerNameList.map((item, index) => {
            newRow.push(
              { text: vm.$n(getMinistryAllocation(dataArray, dis.value, item.value)), style: 'th', alignment: 'right' }
            )
          })
          newRow.push(
            { text: (i18n.locale === 'bn') ? getWarehouseName(dataArray, dis.value, 'name_bn') : getWarehouseName(dataArray, dis.value, 'name'), style: 'th', alignment: 'right' }
          )
          fertilizerNameList.map((item, index) => {
            newRow.push(
              { text: vm.$n(getBadcAllocation(dataArray, dis.value, item.value)), style: 'th', alignment: 'right' }
            )
          })
          newRow.push({})
          allRows.push(newRow)
        })
        pdfContent.push({
          table: {
            headerRows: 3,
            widths: ['7%', '10%', '10%', '10%', '10%', '12%', '10%', '10%', '10%', '11%'],
            body: allRows
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        alignment: 'center',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 12 : 10,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 15]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          fertilizer: {
            margin: [0, 7, 0, 7]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          report_type: {
            fontSize: 9,
            margin: [0, 0, 0, 15]
          },
          titleText: {
            fontSize: 9
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).download('demand-against-allocation-report')
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
