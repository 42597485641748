<template>
  <b-container fluid>
    <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('fertilizerReport.demand_against_allocation_report') }}</h4>
        </template>
    <template v-slot:body>
        <b-overlay>
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
            <b-row>
              <b-col lg="4" sm="12">
                <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="fiscal_year_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('globalTrans.fiscalYear')}}<span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.fiscal_year_id"
                      :options="fiscalYearList"
                      id="fiscal_year_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="4" sm="12">
                <ValidationProvider name="Month"  vid="month_id" rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="month_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('fertilizerSales.month')}}<span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.month"
                      :options="monthList"
                      id="month_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col lg="4" sm="12">
                <ValidationProvider name="Allocation Type"  vid="allocation_type_id"  rules="required|min_value:1">
                  <b-form-group
                      class="row"
                      label-cols-sm="12"
                      label-for="allocation_type_id"
                      slot-scope="{ valid, errors }"
                  >
                    <template v-slot:label>
                      {{$t('fertilizerSales.allocationType')}}  <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                      plain
                      v-model="search.allocation_type_id"
                      :options="allocationTypeList"
                      id="allocation_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col cols="12">
                  <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.search')}}</b-button>
              </b-col>
            </b-row>
        </b-form>
        </ValidationObserver>
        </b-overlay>
    </template>
    </iq-card>
      <b-row >
        <b-col md="12" v-if="showData">
          <b-overlay :show='loading'>
            <iq-card v-if="datas.ministry">
              <template v-slot:body>
                <b-row>
                  <b-col md="12">
                    <iq-card>
                      <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('fertilizerReport.demand_against_allocation_report') }}</h4>
                      </template>
                      <template v-slot:headerAction>
                        <b-button class="btn_add_new" @click="pdfExport">
                          <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                        </b-button>
                      </template>
                      <template v-slot:body>
                        <b-overlay>
                          <b-row mt-5>
                            <b-col md="12" class="table-responsive">
                              <div style="border: 2px solid;margin:10px;padding:10px">
                                <b-row>
                                  <b-col>
                                    <list-report-head :base-url="seedFertilizerServiceBaseUrl" uri="fertilizer/config/report-heading/detail" :org-id="3">
                                      <u>{{ $t('fertilizerReport.demand_against_allocation_report') }}</u>
                                    </list-report-head>
                                    <div class="text-center">
                                      <table style="width:100%;color:black;" class="mb-2">
                                        <tr>
                                          <td align="right" style="width:40%">{{ $t('fertilizerSales.month') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left" style="width:45%">{{ getMonthName(search.month, search.fiscal_year_id) }}</td>
                                        </tr>
                                        <tr>
                                          <td align="right" style="width:40%">{{ $t('fertilizerSales.allocationType') }}</td>
                                          <td align="center" style="width:5%">:</td>
                                          <td align="left" style="width:45%">{{ getAllocationType(search.allocation_type_id) }}</td>
                                        </tr>
                                      </table>
                                    </div>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <div class="col text-right font-weight-bold">
                                    <span class="text-right text-dark"> {{ $t('fertilizerReport.accountMayTons') }}</span>
                                  </div>
                                </b-row>
                                <b-row>
                                  <b-col>
                                    <b-table-simple class="tg" style="" hover bordered small caption-top>
                                      <b-thead>
                                        <b-tr>
                                            <b-td class="tg-0lax" rowspan="3">{{ $t('globalTrans.sl_no') }}</b-td>
                                            <b-td class="tg-0lax" rowspan="3">{{ $t('fertilizerReport.district_name') }} </b-td>
                                            <b-td class="tg-0lax" colspan="3">{{ $t('fertilizerReport.ministry_allocation_of_fer') }} </b-td>
                                            <b-td class="tg-0lax" rowspan="3">{{ $t('movement.warehouseName') }} </b-td>
                                            <b-td class="tg-0lax" colspan="3">{{ $t('fertilizerReport.warehouse_wased_fer') }} </b-td>
                                            <b-td class="tg-0lax" rowspan="3">{{ $t('fertilizerReport.comments') }} </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td class="tg-0lax" colspan="3">{{ $t('movement.fertilizer_name') }} </b-td>
                                            <b-td class="tg-0lax" colspan="3">{{ $t('movement.fertilizer_name') }} </b-td>
                                        </b-tr>
                                        <b-tr>
                                            <b-td class="tg-0lax" v-for="(item, index) in fertilizerNameList" :key="'1_' + index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-td>
                                            <b-td class="tg-0lax" v-for="(item, index) in fertilizerNameList" :key="'2_' + index">{{ $i18n.locale === 'en' ? item.text_en : item.text_bn }}</b-td>
                                        </b-tr>
                                      </b-thead>
                                      <b-tbody>
                                        <b-tr v-for="(district, index) in districtList" :key="'district_' + district.value">
                                          <b-td class="tg-0lax">{{ $n(index + 1) }}</b-td>
                                          <b-td class="tg-0lax">{{ i18 === 'bn' ? district.text_bn: district.text_en }}</b-td>
                                          <b-td class="tg-0lax" v-for="(item, index) in fertilizerNameList" :key="'first_' + district.value + index">
                                            {{ $n(getMinistryAllocation(district.value, item.value)) }}
                                          </b-td>
                                          <b-td class="tg-0lax">
                                            {{ $i18n.locale === 'en' ? getWarehouseName(district.value, 'name') : getWarehouseName(district.value, 'name_bn') }}
                                          </b-td>
                                          <b-td class="tg-0lax" v-for="(item, index) in fertilizerNameList" :key="'seocnd_' + district.value + index">
                                            {{ $n(getBadcAllocation(district.value, item.value)) }}
                                          </b-td>
                                          <b-td v-if="index === 0" :rowspan="districtList.length" class="tg-0lax"></b-td>
                                        </b-tr>
                                      </b-tbody>
                                    </b-table-simple>
                                  </b-col>
                                </b-row>
                              </div>
                            </b-col>
                          </b-row>
                        </b-overlay>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </template>
            </iq-card>
            <div class="panel-body text-center mt-3" v-else>
              <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
            </div>
          </b-overlay>
        </b-col>
      </b-row>
  </b-container>
</template>
<style type="text/css">
.tg  {border-collapse:collapse;border-spacing:0;}
.tg td{text-align: center !important; vertical-align: middle !important;}
.tg th{text-align: center !important; vertical-align: middle !important;}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, demandAgainstAllocationReport } from '../../api/routes'
import ListReportHead from '.././ListReportHead.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import ExportPdf from './export-pdf_details'
import flatpickr from 'flatpickr'

export default {
  name: 'UiDataTable',
  components: {
    ListReportHead,
    ValidationObserver,
    ValidationProvider
  },
  data () {
    return {
      loading: false,
      loadingData: false,
      showData: false,
      search: {
        fiscal_year_id: 0,
        month: 0,
        month_name: 0,
        allo_type: 0,
        allocation_type_id: 0
      },
      datas: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl,
      reportHeadingList: reportHeadingList
    }
  },
  computed: {
    i18 () {
      return this.$i18n.locale
    },
    districtList: function () {
      const tmpData = this.$store.state.commonObj.districtList
      return JSON.parse(JSON.stringify(tmpData))
    },
    fertilizerNameList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
        const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        const tempData = fiscalYears.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    allocationTypeList: function () {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.filter(item => item.status === 1)
        const tempData = type.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
        return tempData
    },
    monthList: function () {
          return this.$store.state.SeedsFertilizer.commonObj.monthListfiscalYear
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
    core.index()
  },
  methods: {
    calculateTotal (data) {
        const total = data.map(r => r.quantity).reduce((a, c) => {
            a = a ? parseInt(a) : 0
            c = c ? parseInt(c) : 0
            return a + c
        })
        return total
    },
    async searchData () {
      this.showData = true
      this.loadData()
    },
    async loadData () {
      this.loading = true
        await RestApi.getData(seedFertilizerServiceBaseUrl, demandAgainstAllocationReport, this.search).then(response => {
        this.loading = false
        if (response.success) {
            if (response.data) {
               this.showData = true
               this.datas = response.data
            } else {
                this.datas = []
            }
        }
      })
      this.loading = false
    },
    pdfExport () {
      const monthName = this.getMonthName(this.search.month, this.search.fiscal_year_id)
      const allocationType = this.getAllocationType(this.search.allocation_type_id)
      const reportTitle = this.$t('fertilizerReport.demand_against_allocation_report')
      ExportPdf.exportPdfDetails(seedFertilizerServiceBaseUrl, '/fertilizer/config/report-heading/detail', 3, reportTitle, this.datas, this, monthName, allocationType, this.search, this.fertilizerNameList)
    },
    getMonthName (month, fiscalYear) {
      const monthList = this.monthList.find(item => item.value === parseInt(month))
      let montName = ''
      if (typeof monthList !== 'undefined') {
        montName = monthList.text
      }
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.find(item => item.value === parseInt(fiscalYear))
      let year = ''
      if (typeof fiscalYears !== 'undefined') {
        year = this.i18 === 'bn' ? fiscalYears.text_bn : fiscalYears.text_en
      }
      this.search.month_name = montName + ' / ' + year
      return montName + ' / ' + year
    },
    getAllocationType (Id) {
        const type = this.$store.state.SeedsFertilizer.commonObj.allocationTypeList.find(item => item.value === parseInt(Id))
      if (typeof type === 'undefined') {
        this.search.allo_type = ''
        return ''
      } else {
        this.search.allo_type = this.i18 === 'bn' ? type.text_bn : type.text_en
        return this.i18 === 'bn' ? type.text_bn : type.text_en
      }
    },
    getWarehouseName (districtId, type) {
      const warehouses = this.datas.warehouse.filter(w => w.district_id === parseInt(districtId))
      let wareHoueInfo = ''
      if (warehouses.length > 0) {
        warehouses.map((item, index) => {
          if (index !== 0) {
            wareHoueInfo = wareHoueInfo + '/' + item[type]
          } else {
            wareHoueInfo = item[type]
          }
        })
      }
      return wareHoueInfo
    },
    getMinistryAllocation (districtId, fertilizerId) {
      const ministry = this.datas.ministry.filter(tsp => tsp.fertilizer_id === fertilizerId && tsp.district_id === parseInt(districtId))
      return ministry.length ? this.calculateTotal(ministry) : ''
    },
    getBadcAllocation (districtId, fertilizerId) {
      const badc = this.datas.badc.filter(tsp => tsp.fertilizer_id === fertilizerId && tsp.district_id === parseInt(districtId))
      return badc.length ? this.calculateTotal(badc) : ''
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
 .row-active {
    color: #212529;
    /* background-color: rgba(0, 0, 0, 0.075); */
    border: 1px solid black !important;
 }
</style>
